import { Button } from "@mui/material";
import FileDownload from "@mui/icons-material/FileDownload";
import useAssetExport from "hooks/assets/useAssetExport";
import { downloadFile } from "utils/files";

export default function AssetsExportGridItem() {
  const { exportAssets, exportedAssets, downloadAssetsExport, setDownloadAssetsExport } = useAssetExport();

  if (downloadAssetsExport) {
    setDownloadAssetsExport(false);
    downloadFile(exportedAssets, "assets.csv");
  }

  return (
    <Button
      startIcon={<FileDownload />}
      size="small"
      onClick={() => {
        exportAssets();
      }}
    >
      Export
    </Button>
  );
}
