import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { api } from "services/api.service";
import { useAlert } from "hooks/alerts/useAlert";
import { downloadFile } from "utils/files";

export default function useAssetRetrievalExport() {
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const { alertError } = useAlert();

  const exportAssetRetrievals = () => {
    setIsLoading(true);
    const args = searchParams.toString();
    api
      .get(`/asset_retrieval_exports.csv?${args.toString()}`)
      .then(({ data }) => {
        const date = new Date();
        const formattedDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
        downloadFile(data, `assets_retrievals(${formattedDate}).csv`);
      })
      .catch(() => {
        alertError("Failed to export asset retrievals, please try again.");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return {
    isLoading,
    exportAssetRetrievals,
  };
}
